import {
    Box,
    Button,
    Icon,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import { LargeStyledTable } from 'app/views/jobs/StyledComponents/StyledComponents'
import { valueCalc } from '../../method/method'

const ReinforcementSteelTable = (props) => {
    let {
        data,
        dia,
        setData,
        setFinalData,
        finalData,
        result,
        setOverall,
        overall,
        handleCustomRequirementChange,
    } = props

    // const [rowsPerPage, setRowsPerPage] = useState(5)
    // const [page, setPage] = useState(0)

    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage)
    // }

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(+event.target.value)
    //     setPage(0)

    const handleDelete = (index, e) => {
        let _data = data.filter((v, i) => i !== index)
        setData(_data)
        let _finalData = finalData
        let mass_avg = 0
        let yeild_avg = 0
        let uts_avg = 0
        let elongation_avg = 0
        let ratio_avg = 0
        let totalMass = 0
        let totalYeild = 0
        let totalUts = 0
        let totalElongation = 0
        let totalRatio = 0
        let dia
        _finalData.forEach((item) => {
            if (item.groupName === result.groupName) {
                if (item.data.length > 0) {
                    _data.forEach((item1, index) => {
                        // let mass = Number(item1.weight / item1.len)
                        totalMass += Number(item1.weightPerMeter)
                        //
                        // let yield_stress = Number(
                        //     (Number(item1.yieldLoad) * 1000) / (mass / 0.00785)
                        // )

                        totalYeild += Number(item1.yieldStress)

                        //
                        // let uts = Number(
                        //     Number(item1.ultimateLoad * 1000) / (mass / 0.00785)
                        // )

                        totalUts += Number(item1.ultimateTensileStress)
                        //
                        // let elongation = Number(
                        //     (100 *
                        //         (Number(item1.finalGaugeLength) -
                        //             Number(item1.originalGaugeLength))) /
                        //     Number(item1.originalGaugeLength)
                        // )
                        totalElongation += Number(item1.percentageOfElongation)

                        //
                        // let ratio = Number(uts / yield_stress)
                        totalRatio += Number(item1._10_perc_uts)

                        dia = item1?.dia
                    })
                    mass_avg = Number(totalMass / _data.length)
                    ratio_avg = Number(totalRatio / _data.length)
                    uts_avg = Number(totalUts / _data.length)
                    yeild_avg = Number(totalYeild / _data.length)
                    elongation_avg = Number(totalElongation / _data.length)
                }
                item.overall = {
                    ...overall,
                    mass_avg: valueCalc(mass_avg),
                    yeild_avg: valueCalc(yeild_avg),
                    uts_avg: valueCalc(uts_avg),
                    elongation_avg: valueCalc(elongation_avg),
                    ratio_avg: valueCalc(ratio_avg),
                }
                setOverall(item.overall)
                if (item.data.length > 0) {
                    // handleCustomRequirementChange(dia + 'mm', item.overall)

                    handleCustomRequirementChange(dia, item.overall)
                } else {
                    handleCustomRequirementChange('', item.overall)
                }
                item.data = _data
            }
        })

        setFinalData(_finalData)
    }
    return (
        <Box sx={{ width: '100%', overflowX: 'auto' }}>
            <LargeStyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Dia/Grade</TableCell>
                        <TableCell align="center">Weight, W(kg)</TableCell>
                        <TableCell align="center">Length, L(m)</TableCell>
                        <TableCell align="center">
                            Weight per meter, (W/L)
                        </TableCell>
                        <TableCell align="center">
                            Cross Sectional Area , A (W per. m/0.00785) (mm²)
                        </TableCell>
                        <TableCell align="center">
                            Original Gauge Length, L1(mm)
                        </TableCell>

                        <TableCell align="center">Yield Load, P (kN)</TableCell>
                        <TableCell align="center">
                            Ultimate Load, F (kN)
                        </TableCell>
                        <TableCell align="center">
                            Final Gauge Length, L2(mm)
                        </TableCell>
                        <TableCell align="center">
                            Yield Stress,(N/mm²)
                        </TableCell>
                        <TableCell align="center">
                            Ultimate Tensile Stress, (F/A)(N/mm²)
                        </TableCell>
                        <TableCell align="center">TS/YS Ratio</TableCell>
                        <TableCell align="center">
                            % of Elongation 100*(L2-L1)/L1
                        </TableCell>

                        {/* <TableCell align="center">Bend</TableCell>
                        <TableCell align="center">ReBend</TableCell> */}
                        <TableCell sx={{ width: 80 }} align="center">
                            Actions
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {data
                        // ?.slice(
                        //     page * rowsPerPage,
                        //     page * rowsPerPage + rowsPerPage
                        // )
                        .map((item, index) => (
                            <TableRow key={index}>
                                <TableCell align="center">{dia}</TableCell>
                                <TableCell align="center">
                                    {item.weight}
                                </TableCell>
                                <TableCell align="center">{item.len}</TableCell>
                                <TableCell align="center">
                                    {item.weightPerMeter}
                                    {/*{item.weightPerMeter = valueCalc(item.weight / item.len)}*/}
                                </TableCell>
                                <TableCell align="center">
                                    {item.crossSectionalArea}
                                    {/*{item.crossSectionalArea = valueCalc(item.weightPerMeter / 0.00785)}*/}
                                </TableCell>
                                <TableCell align="center">
                                    {item.originalGaugeLength}
                                </TableCell>
                                <TableCell align="center">
                                    {item.yieldLoad}
                                </TableCell>
                                <TableCell align="center">
                                    {item.ultimateLoad}
                                </TableCell>
                                <TableCell align="center">
                                    {item.finalGaugeLength}
                                </TableCell>
                                <TableCell align="center">
                                    {item.yieldStress}
                                    {/*{item.yieldStress = valueCalc((item.yieldLoad * 1000) / (item.crossSectionalArea))}*/}
                                </TableCell>
                                <TableCell align="center">
                                    {item.ultimateTensileStress}
                                    {/*{item.ultimateTensileStress = valueCalc((item.ultimateLoad * 1000) / item.crossSectionalArea)}*/}
                                </TableCell>
                                <TableCell align="center">
                                    {item._10_perc_uts}
                                    {/*{item._10_perc_uts = valueCalc(item.ultimateTensileStress / item.yieldStress)}*/}
                                </TableCell>

                                <TableCell align="center">
                                    {
                                        (item.percentageOfElongation =
                                            valueCalc(
                                                100 *
                                                    ((item.finalGaugeLength -
                                                        item.originalGaugeLength) /
                                                        item.originalGaugeLength)
                                            ))
                                    }
                                </TableCell>

                                {/* <TableCell align="center">
                                    {item.bend}
                                </TableCell>
                                <TableCell align="center">
                                    {item.reBend}
                                </TableCell> */}
                                <TableCell align="center">
                                    <Button
                                        onClick={(e) => handleDelete(index, e)}
                                    >
                                        <Icon>delete</Icon>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </LargeStyledTable>

            {/* <TablePagination
                sx={{ px: 2 }}
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'Next Page',
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
        </Box>
    )
}

export default ReinforcementSteelTable
