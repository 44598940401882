import { AddCircle } from '@mui/icons-material'
import {
    Button,
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
} from '@mui/material'
import { AppContext } from 'app/contexts/AppContext'
import JobAccordion from 'app/views/jobs/StyledComponents/JobAccordion'
import {
    HelperText,
    StyledCard,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import { Form, Formik } from 'formik'
import { Fragment, useContext, useState } from 'react'
import * as Yup from 'yup'
import { valueCalc } from '../../method/method'
import ReinforcementSteelTable from './ReinforcementSteelTable'

const initialValues = {
    dia: '',
    weight: '',
    len: '',
    weightPerMeter: '',
    yieldLoad: '',
    ultimateLoad: '',
    crossSectionalArea: '',
    yieldStress: '',
    ultimateTensileStress: '',
    originalGaugeLength: '',
    finalGaugeLength: '',
    percentageOfElongation: '',
    _10_perc_uts: '',
    // bend: '',
    // reBend: '',
}

const validationSchema = Yup.object().shape({
    weight: Yup.number().required('Required'),
    len: Yup.number().required('Required'),
    yieldLoad: Yup.number().required('Required'),
    ultimateLoad: Yup.number().required('Required'),
    // originalGaugeLength: Yup.number().required('Required'),
    finalGaugeLength: Yup.number().required('Required'),
    // bend: Yup.string().required('Required'),
    // reBend: Yup.string().required('Required'),
})

const ReinforcementSteel = (props) => {
    const {
        result,
        isView,
        overall,
        setOverall,
        handleCustomRequirementChange,
        handleRequirementChange,
    } = props

    const { finalData, setFinalData, EditDetails } = useContext(AppContext)
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [dia, setDia] = useState(
        EditDetails?.data?.diaAndGrade ?? initialValues.dia
    )
    const [originalGaugeLength, setOriginalGaugeLength] = useState(
        initialValues.originalGaugeLength
    )
    // const [bend, setBend] = useState(initialValues.bend)
    // const [reBend, setReBend] = useState(initialValues.reBend)

    const [data, setData] = useState(result.data ? result.data : [])
    const [initialState, setInitialState] = useState({ ...initialValues })
    const handleSubmit = (values, { resetForm }) => {
        let arr = [...data]
        arr.push(values)
        let _finalData = finalData

        _finalData.forEach((item) => {
            let mass_avg = 0
            let yeild_avg = 0
            let uts_avg = 0
            let elongation_avg = 0
            let ratio_avg = 0
            let totalMass = 0
            let totalYeild = 0
            let totalUts = 0
            let totalElongation = 0
            let totalRatio = 0
            if (item.groupName === result.groupName && arr.length !== 0) {
                arr.forEach((item1, index) => {
                    let mass = Number(item1.weight / item1.len)
                    let weightPerMeter = Number(valueCalc(mass))
                    item1['weightPerMeter'] = weightPerMeter
                    totalMass += weightPerMeter

                    item1.crossSectionalArea = valueCalc(Number(mass) / 0.00785)
                    let yield_stress = Number(
                        (Number(item1.yieldLoad) * 1000) / (mass / 0.00785)
                    )
                    let yieldStress = Number(valueCalc(yield_stress))
                    item1['yieldStress'] = yieldStress
                    totalYeild += yieldStress
                    //
                    let uts = Number(
                        Number(item1.ultimateLoad * 1000) / (mass / 0.00785)
                    )
                    let ultimateTensileStress = Number(valueCalc(uts))
                    item1['ultimateTensileStress'] = ultimateTensileStress
                    totalUts += ultimateTensileStress
                    //
                    let elongation = Number(
                        (100 *
                            (Number(item1.finalGaugeLength) -
                                Number(item1.originalGaugeLength))) /
                            Number(item1.originalGaugeLength)
                    )
                    let percentageOfElongation = Number(valueCalc(elongation))
                    item1['percentageOfElongation'] = percentageOfElongation
                    totalElongation += percentageOfElongation

                    let ratio1 = Number(uts / yield_stress)
                    let perc_uts = Number(valueCalc(ratio1))
                    item1['_10_perc_uts'] = perc_uts
                    totalRatio += perc_uts
                })
                mass_avg = Number(totalMass / arr.length)
                yeild_avg = Number(totalYeild / arr.length)
                uts_avg = Number(totalUts / arr.length)
                elongation_avg = Number(totalElongation / arr.length)
                ratio_avg = Number(totalRatio / arr.length)
                item.overall = {
                    ...overall,
                    mass_avg: valueCalc(mass_avg),
                    yeild_avg: valueCalc(yeild_avg),
                    uts_avg: valueCalc(uts_avg),
                    elongation_avg: valueCalc(elongation_avg),
                    ratio_avg: valueCalc(ratio_avg),
                }

                setOverall(item.overall)
            }
            // handleCustomRequirementChange(values?.dia + 'mm', item.overall)

            handleCustomRequirementChange(values?.dia, item.overall)
            if (item.groupName === result.groupName) {
                item.data = arr
            }
        })
        arr?.forEach((value, key) => {
            value.dia = dia ?? ''
        })
        setFinalData(_finalData)
        setData(arr)
        setInitialState({ ...initialValues })
        setOpen(false)
    }

    const handleDiaChange = (e) => {
        setDia(e.target.value)
        // setOriginalGaugeLength(Number(e.target.value) * 5)

        setOriginalGaugeLength(
            Number(
                e.target.value?.replace('mm / Fe-550', '').replace('D', '')
            ) * 5
        )
    }
    const handleOriginal = (e) => {
        setOriginalGaugeLength(e.target.value)
    }
    // const handlebendChange = (e) => {
    //     setBend(e.target.value)
    // }
    // const handleReBendChange = (e) => {
    //     setReBend(e.target.value)
    // }

    return (
        <Fragment>
            <Grid item xs={12}>
                <JobAccordion
                    title={result?.groupName}
                    handleCustomRequirementChange={
                        handleCustomRequirementChange
                    }
                    handleRequirementChange={handleRequirementChange}
                    requirementvalue={result}
                    remarksValue={overall?.remarks}
                    overall={overall}
                >
                    <Grid item xs={12}>
                        <Formik
                            initialValues={initialState}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                            enableReinitialize
                        >
                            {(props) => {
                                const {
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                } = props

                                return (
                                    <Form>
                                        <Modal
                                            open={open}
                                            onClose={handleClose}
                                        >
                                            <StyledCard>
                                                <CardHeader
                                                    title={result?.groupName}
                                                />
                                                <CardContent
                                                    sx={{
                                                        m: 4,
                                                    }}
                                                >
                                                    <Grid container spacing={4}>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            xs={12}
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                value={
                                                                    values.weight
                                                                }
                                                                label="Weight, W(kg)"
                                                                required
                                                                name="weight"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                error={
                                                                    errors.weight &&
                                                                    touched.weight
                                                                }
                                                            />
                                                            <HelperText>
                                                                {errors.weight &&
                                                                touched.weight
                                                                    ? errors.weight
                                                                    : ''}
                                                            </HelperText>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            xs={12}
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                label="Length, L(m)"
                                                                value={
                                                                    values.len
                                                                }
                                                                name="len"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                error={
                                                                    errors.len &&
                                                                    touched.len
                                                                }
                                                            />
                                                            <HelperText>
                                                                {errors.len &&
                                                                touched.len
                                                                    ? errors.len
                                                                    : ''}
                                                            </HelperText>
                                                        </Grid>

                                                        <Grid
                                                            item
                                                            lg={6}
                                                            xs={12}
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                label="Yield Load, P  (kN)"
                                                                value={
                                                                    values.yieldLoad
                                                                }
                                                                name="yieldLoad"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                error={
                                                                    errors.yieldLoad &&
                                                                    touched.yieldLoad
                                                                }
                                                            />
                                                            <HelperText>
                                                                {errors.yieldLoad &&
                                                                touched.yieldLoad
                                                                    ? errors.yieldLoad
                                                                    : ''}
                                                            </HelperText>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            xs={12}
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                label="Ultimate Load, F (kN)"
                                                                value={
                                                                    values.ultimateLoad
                                                                }
                                                                name="ultimateLoad"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                error={
                                                                    errors.ultimateLoad &&
                                                                    touched.ultimateLoad
                                                                }
                                                            />
                                                            <HelperText>
                                                                {errors.ultimateLoad &&
                                                                touched.ultimateLoad
                                                                    ? errors.ultimateLoad
                                                                    : ''}
                                                            </HelperText>
                                                        </Grid>

                                                        <Grid
                                                            item
                                                            lg={6}
                                                            xs={12}
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                label="Original Gauge Length,L1(mm)"
                                                                value={
                                                                    (values.originalGaugeLength =
                                                                        originalGaugeLength)
                                                                }
                                                                name="originalGaugeLength"
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    handleChange(
                                                                        e
                                                                    )
                                                                    handleOriginal(
                                                                        e
                                                                    )
                                                                }}
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                error={
                                                                    errors.originalGaugeLength &&
                                                                    touched.originalGaugeLength
                                                                }
                                                            />
                                                            <HelperText>
                                                                {errors.originalGaugeLength &&
                                                                touched.originalGaugeLength
                                                                    ? errors.originalGaugeLength
                                                                    : ''}
                                                            </HelperText>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            xs={12}
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                label="Final Gauge Length,L2(mm)"
                                                                value={
                                                                    values.finalGaugeLength
                                                                }
                                                                name="finalGaugeLength"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                error={
                                                                    errors.finalGaugeLength &&
                                                                    touched.finalGaugeLength
                                                                }
                                                            />
                                                            <HelperText>
                                                                {errors.finalGaugeLength &&
                                                                touched.finalGaugeLength
                                                                    ? errors.finalGaugeLength
                                                                    : ''}
                                                            </HelperText>
                                                        </Grid>
                                                        {/* <Grid
                                                            item
                                                            lg={4}
                                                            md={8}
                                                            sm={8}
                                                            xs={6}
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                label="Requirement"
                                                                value={
                                                                    values.requirement
                                                                }
                                                                name="requirement"
                                                                onChange={
                                                                    handleChange
                                                                }
                                                                onBlur={
                                                                    handleBlur
                                                                }
                                                                error={
                                                                    errors.requirement &&
                                                                    touched.requirement
                                                                }
                                                            />
                                                            <HelperText>
                                                                {errors.requirement &&
                                                                touched.requirement
                                                                    ? errors.requirement
                                                                    : ''}
                                                            </HelperText>
                                                        </Grid> */}

                                                        {/* <Grid
                                                            item
                                                            lg={6}
                                                            xs={12}
                                                        >
                                                            <FormControl
                                                                fullWidth
                                                            >
                                                                <InputLabel id="demo-simple-select-label">
                                                                    Bend
                                                                </InputLabel>
                                                                <Select
                                                                    fullWidth
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select-label"
                                                                    value={bend}
                                                                    name="bend"
                                                                    label="bend"
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        handleChange(
                                                                            e
                                                                        )
                                                                        handlebendChange(
                                                                            e
                                                                        )
                                                                    }}
                                                                >
                                                                    <MenuItem
                                                                        value={
                                                                            'Cracks Observed'
                                                                        }
                                                                    >
                                                                        Cracks
                                                                        Observed */}
                                                                        {/* Positive */}
                                                                    {/* </MenuItem>
                                                                    <MenuItem
                                                                        value={
                                                                            'Cracks Not Observed'
                                                                        }
                                                                    >
                                                                        Cracks
                                                                        Not
                                                                        Observed */}
                                                                        {/* Negative */}
                                                                    {/* </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                            <HelperText>
                                                                {errors.bend &&
                                                                touched.bend
                                                                    ? errors.bend
                                                                    : ''}
                                                            </HelperText>
                                                        </Grid>

                                                        <Grid
                                                            item
                                                            lg={6}
                                                            xs={12}
                                                        >
                                                            <FormControl
                                                                fullWidth
                                                            >
                                                                <InputLabel id="demo-simple-select-label">
                                                                    ReBend
                                                                </InputLabel>
                                                                <Select
                                                                    fullWidth
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select-label"
                                                                    value={
                                                                        reBend
                                                                    }
                                                                    name="reBend"
                                                                    label="reBend"
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        handleChange(
                                                                            e
                                                                        )
                                                                        handleReBendChange(
                                                                            e
                                                                        )
                                                                    }}
                                                                >
                                                                    <MenuItem
                                                                        value={
                                                                            'Cracks Observed'
                                                                        }
                                                                    >
                                                                        Cracks
                                                                        Observed */}
                                                                        {/* Positive */}
                                                                    {/* </MenuItem>
                                                                    <MenuItem
                                                                        value={
                                                                            'Cracks Not Observed'
                                                                        }
                                                                    >
                                                                        Cracks
                                                                        Not
                                                                        Observed */}
                                                                        {/* Negative */}
                                                                    {/* </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                            <HelperText>
                                                                {errors.reBend &&
                                                                touched.reBend
                                                                    ? errors.reBend
                                                                    : ''}
                                                            </HelperText>
                                                        </Grid> */}
                                                    </Grid>
                                                </CardContent>
                                                <CardActions>
                                                    <Button
                                                        size="small"
                                                        onClick={handleClose}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        onClick={handleSubmit}
                                                    >
                                                        Save
                                                    </Button>
                                                </CardActions>
                                            </StyledCard>
                                        </Modal>
                                    </Form>
                                )
                            }}
                        </Formik>

                        {data && (
                            <ReinforcementSteelTable
                                data={data}
                                dia={dia}
                                setData={setData}
                                setFinalData={setFinalData}
                                result={result}
                                finalData={finalData}
                                setOverall={setOverall}
                                overall={overall}
                                handleCustomRequirementChange={
                                    handleCustomRequirementChange
                                }
                            />
                        )}
                        {isView !== true && (
                            <Grid container justifyContent="end" spacing={2}>
                                <Grid item>
                                    <Button
                                        sx={{ boxShadow: 'none', m: 2 }}
                                        variant="contained"
                                        endIcon={<AddCircle />}
                                        onClick={handleOpen}
                                        size="small"
                                    >
                                        Add
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </JobAccordion>
            </Grid>
        </Fragment>
    )
}

export default ReinforcementSteel
