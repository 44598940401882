import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    IconButton,
    Pagination,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import RestoreFromTrashOutlinedIcon from '@mui/icons-material/RestoreFromTrashOutlined'
import { Box, styled } from '@mui/system'
import { MatxLoading, SimpleCard } from 'app/components'
import {
    getUsersList,
    deleteUser,
    deleteArchivedUser,
} from 'app/redux/actions/UserActions'
import { debounce } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import Cookies from 'js-cookie'
import { StyledIcon } from '../jobs/StyledComponents/StyledComponents'
import usePrevious from '../test/helpers/helper'
import Drawer from './DrawerLayout'
import UserCreationForm from './UserCreationForm'

const initialValues = {
    name: '',
    emp_id: '',
    email: '',
    password: '',
    phoneNumber: '',
    roles: '',
    branch: '',
    // signature: '',
    address: '',
    // organizations: ['62da24e23c1a7fd61d5dc526'],
}

const StyledTable = styled(Table)(({ theme }) => ({
    whiteSpace: 'pre',
    minWidth: 850,

    '& thead': {
        '& tr': {
            '& th': {
                paddingLeft: 0,
                paddingRight: 0,
                wordBreak: 'break-word',
                backgroundColor: '#f5f5f5',
            },
        },
    },
    '& tbody': {
        '& tr': {
            '& td': {
                paddingLeft: 0,
                // textTransform: 'capitalize',
                wordBreak: 'break-word',
            },
        },
    },
}))

const UsersTable = ({
    show,
    toggle,
    openDialog,
    setOpenDialog,
    setDialogMessage,
    dialogMessage,
    handleDialogClose,
}) => {
    const [resData, setResData] = useState()
    const [value, setValue] = useState({})
    const [open, setOpen] = useState(false)
    const [pages, setPage] = useState(1)
    const [usersData, setUsersData] = useState([])
    const [usersStatus, setUsersStatus] = useState([])
    const [initialState, setInitialState] = useState({ ...initialValues })
    const [isSignatureAdded, setIsSignatureAdded] = useState(false)
    const admin = sessionStorage.getItem('userRole')
    const [loading, setLoading] = useState(true)

    const [query, setQuery] = useState('')
    const [active, setActive] = useState('ACTIVE')
    const loginUserId = Cookies.get('userId')
    const [snack, setSnack] = useState({
        snackOpen: false,
        snackVaraint: '',
        snackMsg: '',
    })

    const getUsersData = async (pageNo) => {
        getUsersList(
            { page: pageNo ?? 1, q: query, size: 10, status: active ,loginUserId: loginUserId },
            (res) => {
                setUsersData(res?.data?.docs)
                setResData(res?.data?.totalPages)
                setPage(res?.data?.page)
                setUsersStatus(res?.data?.status)

                setLoading(false)
            }
        )
    }

    useEffect(() => {
        getUsersData()
    }, [query, active])

    const handleChangePage = (event) => {
        getUsersData(parseInt(event))
    }

    const handleOpen = (data) => {
        setOpen(true)
        setValue(data)
    }
    const handleSnackClose = () => setSnack(false)

    const handleClose = () => setOpen(false)

    const handleDelete = (value, index) => {
        let status = { status: 'ARCHIVED' }
        let jobId = value._id
        deleteArchivedUser({ jobId, status }, (res) => {
            setOpen(false)

            let msg = res?.status
            // let _data = usersData.filter((v, i) => v._id !== value._id)
            // setUsersData(_data)

            if (msg === 200) {
                getUsersData()

                setSnack({
                    ...snack,
                    snackOpen: 'true',
                    snackMsg: 'Delete Successfully',
                    snackVaraint: 'success',
                })
            } else {
                setSnack({
                    ...snack,
                    snackOpen: 'false',
                    snackMsg: msg,
                    snackVaraint: 'error',
                })
            }
        })
    }

    const Column = [
        {
            name: 'Name',
            dataField: 'name',
        },
        {
            name: 'Email',
            dataField: 'email',
        },
        {
            name: 'Phone Number',
            dataField: 'phoneNumber',
        },
        {
            name: 'status',
            dataField: 'status',
        },
        // {
        //     name: 'Branch',
        //     dataField: 'branch_no',
        // },

        {
            name: 'Roles',

            formatter: (data = '') => {
                return <div>{data?.roles?.name ?? ''}</div>
            },
        },
        {
            name: 'Address',
            dataField: 'address',
        },
        {
            name: 'Organization',
            dataField: 'organization',
            formatter: (data = '') => {
                return <div>{data?.organizations?.[0]?.name ?? ''}</div>
            },
        },

        {
            name: 'Action',
            dataField: 'name',
            formatter: (data) => {
                return (
                    <div>
                        <IconButton
                            onClick={() => {
                                toggle()
                                setInitialState({
                                    ...data,
                                    roles: data?.roles?.name,
                                    branch: data?.branch?.[0]?.name ?? '',
                                })
                                setIsSignatureAdded(true)
                            }}
                        >
                            <StyledIcon>edit</StyledIcon>
                        </IconButton>
                        {/*<IconButton*/}
                        {/*    onClick={(e) => {*/}
                        {/*        handleOpen(data)*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <StyledIcon>delete</StyledIcon>*/}
                        {/*</IconButton>*/}
                    </div>
                )
            },
        },
    ]

    //Search handler
    const prevQuery = usePrevious(query)

    const handleSearch = (e) => {
        setQuery(e.target.value)
    }

    // useEffect(() => {
    //     if (prevQuery !== query) {
    //         setPage(1);
    //     }
    // }, [query]);

    const debouncedResults = useMemo(() => {
        return debounce(handleSearch, 600)
    }, [])

    useEffect(() => {
        return () => {
            debouncedResults.cancel()
        }
    })

    const handleUserStatusChange = (selectedStatus) => {
        // const updatedUsersData = usersData.map((user) => {
        //     if (user._id === userId) {
        //         const newStatus = user.status === 'Active' ? 'ARCHIVED' : 'Active';
        //         return { ...user, status: newStatus };
        //     }
        //     return user;
        // });
        // console.log("Updated Users Data:", updatedUsersData);
        //
        // // Set the updated users data to the state
        // setUsersData(updatedUsersData);
        setActive(selectedStatus)
        console.log('Selected Status:', selectedStatus)
    }

    if (loading) {
        return <MatxLoading />
    } else {
        return (
            <SimpleCard
                title="Users"
                handleSearch={debouncedResults}
                handleUserStatusChange={handleUserStatusChange}
                label={'User Name/ E-mail / Phone No.'}
            >
                <Box width="100%">
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            height: '100%',

                            overflowX: 'auto',
                        }}
                    >
                        <StyledTable>
                            <TableHead>
                                <TableRow>
                                    {Column.map((column) => (
                                        <TableCell
                                            align="center"
                                            key={column.name}
                                        >
                                            {column.name}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {usersData?.map((sample, index) => (
                                    <TableRow key={sample._id ?? index}>
                                        {Column.map((column, subIndex) => (
                                            <TableCell
                                                align="center"
                                                key={`${index}${subIndex}`}
                                            >
                                                {column.formatter
                                                    ? column.formatter(sample)
                                                    : sample?.[column.dataField]
                                                      ? sample?.[
                                                            column.dataField
                                                        ]
                                                      : sample?.branch?.[
                                                            column.dataField
                                                        ] ?? ''}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </StyledTable>
                        <Grid item>
                            <Pagination
                                onChange={(e, val) => handleChangePage(val)}
                                count={resData}
                                variant="text"
                                page={pages}
                                color="primary"
                            />
                            <Drawer
                                initialValues={initialValues}
                                toggle={toggle}
                                show={show}
                                callBack={getUsersData}
                                state={initialState}
                                setState={setInitialState}
                                signature1={isSignatureAdded}
                                setSignature1={setIsSignatureAdded}
                                component={UserCreationForm}
                                dialogMessage={dialogMessage}
                                setDialogMessage={setDialogMessage}
                                setOpenDialog={setOpenDialog}
                                openDialog={openDialog}
                                handleDialogClose={handleDialogClose}
                            />
                        </Grid>
                    </Grid>

                    <Snackbar
                        open={snack.snackOpen}
                        severity={snack.snackVaraint}
                        autoHideDuration={2000}
                        onClose={() =>
                            setSnack({
                                ...snack,
                                snackOpen: false,
                            })
                        }
                    >
                        <Alert onClose={handleSnackClose} severity="success">
                            {snack.snackMsg}
                        </Alert>
                    </Snackbar>
                    {/* Delete Dialogue */}
                    <Dialog
                        // fullScreen={fullScreen
                        value={value}
                        open={open}
                        onClose={handleClose}
                        // TransitionComponent={Transition}
                    >
                        <DialogContent>
                            <DialogContentText>
                                Are you sure you want to Delete?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button
                                onClick={(e, index) =>
                                    handleDelete(value, index)
                                }
                                autoFocus
                                variant="contained"
                            >
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </SimpleCard>
        )
    }
}

export default UsersTable
