export const DetailsTableHeading = {
    'Fine Aggregate': {
        testCarried: 'Test Carried',
        unit: 'Unit',
        result: 'Result',
        testMethod: 'Test Method',
        requirement: 'Requirement as per IS:383-2016',
    },
    'Coarse Aggregate': {
        testCarried: 'Test Carried',
        unit: 'Unit',
        result: 'Result',
        testMethod: 'Test Method',
        requirement: 'Requirement as per IS:383-2016',
    },
    Cement: {
        testCarried: 'Test Carried',
        unit: 'Unit',
        standardRef: 'standardRef',
        result: 'Result',
        requirement: 'Requirements as per IS 269-2015 (Reaff 2020)',
        // remark: 'Remark',
    },
    'Cement(Physical)': {
        testCarried: 'Test Carried',
        unit: 'Unit',
        //standardRef: 'standardRef',
        result: 'Result',
        requirement: 'Requirements as per IS 269-2015 (Reaff 2020)',
        // remark: 'Remark',
    },
    'Fly ash': {
        testCarried: 'Test Carried',
        unit: 'Unit',
        result: 'Result',
        testMethod: 'Test Method',
        requirement: 'Requirement as per IS:383-2016',
    },
    'Reinforcement Steel (rebar)': {
        dia: 'Dia mm',
        weightPerMeter: 'Mass/ meter',
        yieldStress: 'Yield Stress',
        ultimateTensileStress: 'U T S',
        percentageOfElongation: '% of Elongation',
        _10_perc_uts: 'TS / YS  Ratio',
        // bend: 'Bend',
        // reBend: 'Re-Bend',

        // yieldLoad: 'Yield Load, P  (kN)',
        // ultimateLoad: 'Ultimate Load, F (kN)',
        // crossSectionalArea: 'Cross Sectional Area',
        // originalGaugeLength: 'Original Gauge Length',
        // finalGaugeLength: 'Final Gauge Length, L2(mm)',
    },
    'Paver blocks': {
        testCarried: 'Test Carried',
        unit: 'Unit',
        result: 'Result',
        testMethod: 'Test Method',
        requirement: 'Requirement as per IS:383-2016',
    },
    Bricks: {
        testCarried: 'Test Carried',
        unit: 'Unit',
        result: 'Result',
        testMethod: 'Test Method',
        requirement: 'Requirement as per IS:383-2016',
    },
    'Autoclaved Aerated Concrete Blocks (AAC Blocks)': {
        testCarried: 'Test Carried',
        unit: 'Unit',
        result: 'Result',
        testMethod: 'Test Method',
        requirement: 'Requirement as per IS:383-2016',
    },
    'AAC Blocks': {
        testCarried: 'Test Carried',
        unit: 'Unit',
        result: 'Result',
        testMethod: 'Test Method',
        requirement: 'Requirement as per IS:383-2016',
    },
    'Bricks & pre-casted Blocks': {
        testCarried: 'Test Carried',
        unit: 'Unit',
        result: 'Result',
        testMethod: 'Test Method',
        requirement: 'Requirement as per IS:383-2016',
    },
    'Red Clay Bricks': {
        testCarried: 'Test Carried',
        unit: 'Unit',
        result: 'Result',
        testMethod: 'Test Method',
        requirement: 'Requirement as per IS:383-2016',
    },
    'Concrete Blocks': {
        testCarried: 'Test Carried',
        unit: 'Unit',
        result: 'Result',
        testMethod: 'Test Method',
        requirement: 'Requirement as per IS:383-2016',
    },
    'Concrete Blocks (Ho low & Solid)': {
        testCarried: 'Test Carried',
        unit: 'Unit',
        result: 'Result',
        testMethod: 'Test Method',
        requirement: 'Requirement as per IS:383-2016',
    },
    'Solid Blocks': {
        testCarried: 'Test Carried',
        unit: 'Unit',
        result: 'Result',
        testMethod: 'Test Method',
        requirement: 'Requirement as per IS:383-2016',
    },
    'Hardened Concrete': {
        testCarried: 'Test Carried',
        unit: 'Unit',
        result: 'Result',
        testMethod: 'Test Method',
        requirement: 'Requirement as per IS:383-2016',
    },
    Soil: {
        description: 'Description',
        result: 'Result',
        requirement: 'Requirements ',
    },
    'Carbonation test': {
        testCarried: 'Test Carried',
        unit: 'Unit',
        result: 'Result',
        testMethod: 'Test Method',
        requirement: 'Requirement as per IS:383-2016',
    },
    'Concrete Blocks (Hollow & Solid)': {
        testCarried: 'Test Carried',
        unit: 'Unit',
        result: 'Result',
        testMethod: 'Test Method',
        requirement: 'Requirement as per IS:383-2016',
    },
    'Crack width measurement in reinforcement concrete': {
        testCarried: 'Test Carried',
        unit: 'Unit',
        result: 'Result',
        testMethod: 'Test Method',
        requirement: 'Requirement as per IS:383-2016',
    },
    'Half-Cell Potential difference test': {
        testCarried: 'Test Carried',
        unit: 'Unit',
        result: 'Result',
        testMethod: 'Test Method',
        requirement: 'Requirement as per IS:383-2016',
    },
    'Fresh Concrete': {
        testCarried: 'Test Carried',
        unit: 'Unit',
        result: 'Result',
        testMethod: 'Test Method',
        requirement: 'Requirement as per IS:383-2016',
    },
    'Hardened Concrete (Cube & Concrete core)': {
        testCarried: 'Test Carried',
        unit: 'Unit',
        result: 'Result',
        testMethod: 'Test Method',
        requirement: 'Requirement as per IS:383-2016',
    },
    'Hardened Concrete (Rebound Hammer)': {
        testCarried: 'Test Carried',
        unit: 'Unit',
        result: 'Result',
        testMethod: 'Test Method',
        requirement: 'Requirement as per IS:383-2016',
    },
    Soils: {
        testCarried: 'Test Carried',
        unit: 'Unit',
        result: 'Result',
        testMethod: 'Test Method',
        requirement: 'Requirement as per IS:383-2016',
    },
    'Soil Core': {
        testCarried: 'Test Carried',
        unit: 'Unit',
        result: 'Result',
        testMethod: 'Test Method',
        requirement: 'Requirement as per IS:383-2016',
    },
    'Construction Water': {
        tests: 'Test Parameter',
        unit: 'Unit',
        standard_ref: ' Method of Ref',
        result: 'Results',
        req: 'Min - Limit as per Standard Ref',
        requirement_absence_source: 'Max - Limit as per Standard Ref',
    },
    'Water Neutralization': {
        tests: 'Test Parameter',
        unit: 'Unit',
        standard_ref: ' Method of Ref',
        result: 'Results',
        req: 'Min - Limit as per Standard Ref',
        requirement_absence_source: 'Max - Limit as per Standard Ref',
    },
    'Drinking Water (RO Water)': {
        tests: 'Test Parameter',
        unit: 'Units',
        standard_ref: 'Test Method',
        result: 'Results',
        req: 'Acceptable Limit',
        requirement_absence_source:
            'Permissible limit in the absence of alternative source',
    },
    'Swimming Pool Water': {
        tests: 'Test Parameter',
        unit: 'Unit',
        standard_ref: 'Test Method',
        result: 'Result',
        req: 'Requirement Limits as per Standard Ref',
    },
    'Cooling Tower Water': {
        tests: 'Test Parameter',
        unit: 'Unit',
        standard_ref: 'Test Method',
        result: 'Result',
        req: 'Requirement Limits as per Standard Ref',
    },
    'Coupler (Chemical)': {
        tests: 'Paramter Name',
        min: 'Minimum',
        max: 'Maximum',
    },
    'Drinking Water (Bore Water)': {
        tests: 'Test Parameter',
        unit: 'Unit',
        standard_ref: 'Test Method',
        result: 'Result',
        req: 'Requirement Limits as per Standard Ref',
    },
    'Waste Water (STP Treated Water)': {
        tests: 'Test Parameter',
        unit: 'Unit',
        standard_ref: 'Method of Ref',
        result: 'Result',
        req: 'Limits as per PCB',
    },
    'Chemical Waste Water (STP Treated Water)': {
        tests: 'Test Parameter',
        unit: 'Unit',
        standard_ref: 'Method of Ref',
        result: 'Result',
        req: 'Limits as per PCB',
    },
    'Waste Water (ETP Treated Water)': {
        tests: 'Test Parameter',
        unit: 'Unit',
        standard_ref: 'Method of Ref',
        result: 'Result',
        req: 'Limits as per PCB',
    },
    'Waste Water (ETP Effluent Water)': {
        tests: 'Test Parameter',
        unit: 'Unit',
        standard_ref: 'Method of Ref',
        result: 'Result',
        req: 'Limits as per PCB',
    },
    'Well Water': {
        tests: 'Test Parameter',
        unit: 'Units',
        standard_ref: 'Test Method',
        result: 'Results',
        req: 'Acceptable Limit',
        requirement_absence_source:
            'Permissible limit in the absence of alternative source',
    },
}
