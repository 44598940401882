import Axios from 'app/configs/Axios'

////////////////////////////////
export const fetchTestSampleList = (props, callback, errorCallback) => {
    console.log('props === ', props)
    return Axios({
        method: 'get',
        url: '/customerSample',
        params: {
            ...props,
        },
    })
        .then((res) => callback(res))
        .catch((err) => errorCallback(err))
}
//TestTable StatusCount
export const fetchProgressData = (props, callback, errorCallback) => {
    console.log('props === ', props)
    return Axios({
        method: 'get',
        url: '/customerStatus',
        params: {
            ...props,
        },
    })
        .then((res) => callback(res))
        .catch((err) => errorCallback(err))
}

export const deleteSampleDataByArchived = (props, callback, errorCallback) => {
    return Axios({
        method: 'patch',
        url: `/customerSample/${props.test_id}`,
        data: props.status,
    })
        .then((res) => callback(res))
        .catch((err) => errorCallback(err))
}

export const deleteSampleDataByID = (test_id, callback, errorCallback) => {
    return Axios({
        method: 'delete',
        url: `/customerSample/${test_id}`,
    })
        .then((res) => callback(res))
        .catch((err) => errorCallback(err))
}

export const fetchViewData = (test_id, callback, errorCallback) => {
    return Axios({
        method: 'get',
        url: `/customerSample/${test_id}`,
    })
        .then((res) => callback(res))
        .catch((err) => errorCallback(err))
}

export const fetchAssigneeData = (role, callback, errorCallback) => {
    return Axios({
        method: 'get',
        url: '/users',
        params: { role },
    })
        .then((res) => callback(res))
        .catch((err) => errorCallback(err))
}

export const fetchUserList = (props, callback, errorCallback) => {
    return Axios({
        method: 'get',
        url: '/users',
        params: { ...props },
    })
        .then((res) => callback(res))
        .catch((err) => errorCallback(err))
}

// Test Table Sorting

export const sortTestSampleData = (props, callback, errorCallback) => {
    return Axios({
        method: 'get',
        url: '/customerSample',
        params: { ...props },
    })
        .then((res) => callback(res))
        .catch((err) => errorCallback(err))
}
export const fetchProjects = (callback, errorCallback) => {
    return Axios({
        method: 'get',
        url: '/customerSample',
    })
        .then((res) => {
            callback(res)
        })
        .catch((err) => errorCallback(err))
}

// Discipline API

export const fetchDisciplineList = (callback, errorCallback) => {
    return Axios({
        method: 'get',
        url: '/disciplines',
    })
        .then((res) => {
            callback(res)
        })
        .catch((err) => errorCallback(err))
}

export const fetchGroupList = (discipline_id, callback, errorCallback) => {
    return Axios({
        method: 'get',
        url: '/groups',
        params: {
            discipline_id,
        },
    })
        .then((res) => {
            callback(res)
        })
        .catch((err) => errorCallback(err))
}

export const fetchSampleList = (group_id, callback, errorCallback) => {
    return Axios({
        method: 'get',
        url: '/materials',
        params: {
            group_id,
        },
    })
        .then((res) => {
            callback(res)
        })
        .catch((err) => errorCallback(err))
}

export const fetchUlrNo = (previousUlrs, callback, errorCallback) => {
    return Axios({
        method: 'get',
        url: '/jobs/ulr',
        params: { previousUlrs },
    })
        .then((res) => {
            callback(res)
        })
        .catch((err) => errorCallback(err))
}
//CUSTOMER Search API

export const fetchCustomerList = (props, callback, errorCallback) => {
    return Axios({
        method: 'get',
        url: '/users',
        params: { ...props },
    })
        .then((res) => {
            callback(res)
        })
        .catch((err) => errorCallback(err))
}

//Sample Reg
export const createSample = (reqbody, callBack, error) => {
    Axios({
        method: 'post',
        url: `/customerSample`,
        data: reqbody,
    })
        .then((res) => callBack(res))
        .catch((err) => {
            error(err?.response?.data)
        })
}

export const editSample = (id, reqbody, callBack, error) => {
    Axios({
        method: 'put',
        url: `/customerSample/${id}`,
        data: reqbody,
    })
        .then((res) => callBack(res))
        .catch((err) => {
            error(err?.response?.data)
        })
}

export const getUsers = async (role) => {
    try {
        const res = await Axios.get(`/users`, {
            params: { role },
        })
        return res.data.data.map((item) => ({
            _id: item._id,
            name: item.name,
            signature: item.signature,
        }))
    } catch (err) {
        console.error(err)
    }
}
export const getSampleCollection = (id, callBack, error) => {
    Axios({
        method: 'get',
        url: `/sample-requests/${id}`,
    })
        .then((res) => callBack(res))
        .catch((err) => {
            error(err)
            console.error(err)
        })
}

export const createSampleCollection = (reqbody, callBack, error) => {
    Axios({
        method: 'post',
        url: `/sample-requests`,
        data: reqbody,
    })
        .then((res) => callBack(res))
        .catch((err) => {
            error(err?.response?.data?.message)
            // console.error(err.response)
        })
}

export const editSampleCollection = (id, reqbody, callBack) => {
    Axios({
        method: 'put',
        url: `/sample-requests/${id}`,
        data: reqbody,
    })
        .then((res) => callBack(res))
        .catch((err) => console.error(err))
}

export const deleteSample = (id, callBack) => {
    Axios({
        method: 'delete',
        url: `/csample-requests/${id}`,
    })
        .then((res) => callBack(res))
        .catch((err) => console.error(err))
}

//Client Publish
export const publishCustomerSample = (id, callBack, errorCallback) => {
    Axios({
        method: 'put',
        url: `/customerSample/${id}/publish`,
    })
        .then((res) => callBack(res))
        .catch((err) => errorCallback(err))
}
