import { useTheme } from '@emotion/react'
import {
    Alert,
    Button,
    Modal,
    Stack,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    Icon,
    IconButton,
    Pagination,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
} from '@mui/material'
import { Box, styled } from '@mui/system'
import { getDocReport } from 'app/assets/Constant/constant'
// import { publishJobByID } from 'api/Job'
import { configLicense } from 'Conflit/Features'
import { MatxLoading, SimpleCard } from 'app/components'
import { getMaterialData } from 'app/redux/actions/AppAction'
import {
    deleteArchivedJobById,
    getJobTableData,
    getJobView,
    getReportJobDocument,
    getReportJobDocumentByObj,
    getSortedJobTableList,
    publishJobByID,
} from 'app/redux/actions/JobActions'
import {
    getNotificationJobData,
    getNotificationsData,
} from 'app/redux/actions/NotificationAction'
import {
    Small,
    StyledIcon,
    Transition,
} from 'app/views/jobs/StyledComponents/StyledComponents'
import usePrevious from 'app/views/jobs/shared/method/method'
import Cookies from 'js-cookie'
import { debounce } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

const StyledTable = styled(Table)(({ theme }) => ({
    whiteSpace: 'pre',
    minWidth: 850,

    '& thead': {
        '& tr': {
            '& th': {
                paddingLeft: 0,
                paddingRight: 0,
                wordBreak: 'break-word',
                backgroundColor: '#f5f5f5',
                cursor: 'default',
            },
        },
    },
    '& tbody': {
        '& tr': {
            '& td': {
                paddingLeft: 0,
                textTransform: 'capitalize',
                wordBreak: 'break-word',
                cursor: 'default',
            },
        },
    },
}))
const StyledButton = styled(Button)(({ theme }) => ({
    fontSize: 'medium',
    width: '120px',
    height: '40px',
}))

const CustomBackdrop = styled(Modal)(({ theme }) => ({
    '.MuiBackdrop-root': {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
}))
const styledMenu = styled(Menu)(({ theme }) => ({
    '.css-1m0jcr3-MuiPaper-root-MuiPopover-paper-MuiMenu-paper': {
        boxShadow:
            '0px 0px 0px 0px rgba(0,0,0,0.2), -1px -1px 11px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12)',
        sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
        },
    },
}))

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 155,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    //boxShadow: 24,
    borderRadius: 4,
    //pt: 1,
    px: 1,
    pb: 1,
}

const ReportTable = () => {
    const params = useParams()
    let search = window.location.search
    let param = new URLSearchParams(search)
    let type = param.get('type')
    let page_ = param.get('page')

    const { palette } = useTheme()
    const bgError = palette.error.dark
    const bgPrimary = palette.primary.main
    const bgWarning = palette.warning.main
    const bgSuccess = palette.success.main
    const navigate = useNavigate()

    const [sorting, setSorting] = useState({
        job_no: '',
        test: '',
        allocated_to: '',
        start_date: '',
        due_date: '',
    })
    const [snack, setSnack] = useState({
        snackOpen: false,
        snackVaraint: '',
        snackMsg: '',
    })
    const [value, setValue] = useState({})
    const [open, setOpen] = useState(false)

    const getStatus = () => {
        if (type === 'ongoing') return 'PROGRESS'
        else if (type === 'completed') return 'COMPLETE'
        else if (type === 'paid') return 'PAID'
        else return ''
    }

    const [dialog, setDialog] = useState(false)

    const [pages, setPages] = useState(1)

    const [resData, setResData] = useState({})
    const [view, setView] = useState([])

    const [orderDirection, setOrderDirection] = useState('desc')
    const [valueOrderBy, setValueOrderBy] = useState('start_date')
    const [loading, setLoading] = useState(true)
    const [query, setQuery] = useState('')
    const [boolean, setBoolean] = useState(false)
    const [doc, setDoc] = useState('')
    const [materialId, setMaterialId] = useState('')
    const [totalDocs, setTotalDocs] = useState()
    const [opened, setOpened] = useState(false)

    const dispatch = useDispatch()
    const { materialNames } = useSelector((state) => state.appReducer)

    let status = 'COMPLETE,APPROVED,PAID'

    let user_id = Cookies.get('userId')

    const fetchData = (pageNo) => {
        getJobTableData(
            {
                user_id: user_id,
                q: query,
                status: status,
                materialId,
                size: 10,
                page: pageNo,
                [valueOrderBy]: orderDirection,
            },

            (res) => {
                setView(res?.docs)
                setTotalDocs(res?.totalDocs)
                setResData(res?.totalPages)
                setPages(res?.page ? res?.page : 1)
                {
                    pageNo &&
                        navigate({
                            pathname: '/report',
                            search: `?page=${res?.page ?? 1}`,
                        })
                }
                // if(pageNo) {
                //     navigate({
                //         pathname: '/report',
                //         search: `?page=${res?.page ?? 1}`,
                //     })
                // }
                // {
                //     (pageNo) && ;
                // }
                // if (materialNames.length == 0) {
                //     dispatch(getMaterialData())
                // }
                setLoading(false)
            }
        )
    }
    const prevPage = usePrevious(page_)

    useEffect(() => {
        if (!view && prevPage !== page_ && pages != page_) {
            fetchData(page_)
        }
        if (materialNames?.length == 0) {
            dispatch(getMaterialData())
        }
    }, [page_])

    useEffect(() => {
        if (materialId) {
            fetchData()
        } else {
            fetchData(page_)
        }
    }, [materialId])

    const fetchNotification = () => {
        dispatch(
            getNotificationsData({
                user_id: user_id,
                status: 'ARCHIVED',
                visited: false,
                size: 100,
            })
        )
        dispatch(
            getNotificationJobData({
                user_id: user_id,
                status: 'ARCHIVED',
                visited: false,
                size: 100,
            })
        )
    }

    const handleChangePage = (event) => {
        setPages(parseInt(event))
        fetchData(parseInt(event))
    }

    const handleRequestSort = (event, property) => {
        const isAscending =
            valueOrderBy === property && orderDirection === 'asc'

        setValueOrderBy(property)
        let newSorting = {
            // ...sorting,
            [property]: sorting[property] === 'asc' ? 'desc' : 'asc',
        }
        setSorting(newSorting)
        setOrderDirection(isAscending ? 'desc' : 'asc')

        getSortedJobTableList(
            {
                [property]: sorting[property] === 'asc' ? 'desc' : 'asc',
                page: pages,
                size: 10,
                status: status,
                q: query,
                materialId: materialId,
            },
            (res) => {
                setView(res?.docs)
                setResData(res?.totalPages)
            }
        )
    }

    const createSortHanlder = (property) => (event) => {
        handleRequestSort(event, property)
    }

    const OnhandleEdit = (value) => {
        getJobView(value?._id, (res) => {
            navigate(`/job/edit/${res?.data?._id}`)
        })
    }

    const OnhandleView = (user) => {
        navigate(`/job/view/${user?._id}`)
    }
    const role = Cookies.get('userRole')

    const handleDelete = (value, index) => {
        let status = { status: 'ARCHIVED' }
        let jobId = value._id
        deleteArchivedJobById({ jobId, status }, (res) => {
            let msg = res?.Message
            setOpen(false)
            let _data = view.filter((v, i) => v._id !== value._id)
            setView(_data)
            if (res.status === 200) {
                fetchData(pages)

                fetchNotification()
                setSnack({
                    ...snack,
                    snackOpen: 'true',
                    snackMsg: msg,
                    snackVaraint: 'success',
                })
            } else {
                setSnack({
                    ...snack,
                    snackOpen: 'false',
                    snackMsg: msg,
                    snackVaraint: 'error',
                })
            }
        })
    }

    const handleReport = (e) => {
        if (anchorElJob) {
            let jobId = anchorElJob._id
            let materialId = anchorElJob?.material?._id
            let materialName = anchorElJob?.material?.name
            if (
                materialId &&
                jobId &&
                materialName &&
                getDocReport.includes(materialName)
            ) {
                getReportJobDocument({ materialId, jobId }, (res) => {
                    if (res) {
                        window.open(res, '_blank')
                    }
                })
                // getReportJobDocumentByObj({materialId,jobId},(res) => {
                //     console.log(res);
                // })
            } else {
                navigate(`/pdf/view/${jobId}?type=report`)
            }
        }
    }

    const handleObservation = (user) => {
        navigate(`/pdf/view/${user._id}?type=observation`)
    }
    const handleTestReport = (user, name) => {
        if (name === 'Coarse Aggregate' || name === 'Fine Aggregate') {
            navigate(`/pdf/view/${user._id}?type=report`)
        } else {
            navigate(`/pdf/view/${user._id}?type=testReport`)
        }
    }

    const handleReportOnly = () => {
        if (anchorElJob) {
            let jobId = anchorElJob._id
            let materialId = anchorElJob?.material?._id
            let materialName = anchorElJob?.material?.name
            if (
                materialId &&
                jobId &&
                materialName &&
                getDocReport.includes(materialName)
            ) {
                getReportJobDocument({ materialId, jobId }, (res) => {
                    if (res) {
                        window.open(res, '_blank')
                    }
                })
                // getReportJobDocumentByObj({materialId,jobId},(res) => {
                //     console.log(res);
                // })
            } else {
                navigate(`/pdf/view/${jobId}?type=reportOnly`)
            }
        }
    }

    const handlePublish = (value) => {
        publishJobByID(value._id, (res) => {
            let msg = res?.message

            setDialog(false)

            if (res.status === 200) {
                value.publish = true
                fetchData(pages)

                setSnack({
                    ...snack,
                    snackOpen: 'true',
                    snackMsg: msg,
                    snackVaraint: 'success',
                })
            } else {
                setSnack({
                    ...snack,
                    snackOpen: 'false',
                    snackMsg: msg,
                    snackVaraint: 'error',
                })
            }
        })
    }

    const handleSnackClose = () => setSnack(false)

    const handleOpen = (value) => {
        setOpen(true)
        setValue(value)
    }

    const handleClose = () => setOpen(false)

    const handlePublishOpen = (value) => {
        setDialog(true)
        setValue(value)
    }

    const handlePublishClose = () => setDialog(false)

    const statusBadge = (status) => {
        if (status === 'TODO') return <Small bgcolor={bgPrimary}> TODO </Small>
        else if (status === 'COMPLETE')
            return <Small bgcolor={bgSuccess}> Completed </Small>
        else if (status === 'APPROVED')
            return <Small bgcolor={bgPrimary}> Approved </Small>
        else if (status === 'PAID')
            return <Small bgcolor={bgWarning}> Paid </Small>
        else return <Small bgcolor={bgWarning}> Progress</Small>
    }

    const publishBadge = (publish) => {
        if (publish === true)
            return (
                <Tooltip title="Published Job" arrow>
                    <Icon color="success">check_circle</Icon>
                </Tooltip>
            )
        else return <Icon color="action">cancel</Icon>
    }

    //Search handler
    const prevQuery = usePrevious(query)

    const handleSearch = (e) => {
        setQuery(e.target.value)
    }

    useEffect(() => {
        if (prevQuery !== query) {
            setPages(1)
            if (query?.length > 0) {
                fetchData()
            } else {
                fetchData(page_)
            }
        }
    }, [query])

    const debouncedResults = useMemo(() => {
        return debounce(handleSearch, 400)
    }, [])

    useEffect(() => {
        return () => {
            debouncedResults.cancel()
        }
    })

    const handleFilter = (value) => {
        setMaterialId(value?._id)
    }
    const handleReset = () => {
        setMaterialId('')
        // setSelectedDiscipline(null);
        // setSelectedGroup(null);
        // setSelectedSamples(null);
    }

    const handleOpened = () => setOpened(true)
    //const handleClosed = () => setOpened(false);
    const [anchorEl, setAnchorEl] = useState(null)
    const [anchorElJob, setAnchorElJob] = useState(null)
    const open1 = Boolean(anchorEl)

    const handleClick = (event, job) => {
        if (job?.material?.name && getDocReport.includes(job?.material?.name)) {
            let jobId = job._id
            let materialId = job?.material?._id
            getReportJobDocument({ materialId, jobId }, (res) => {
                if (res) {
                    window.open(res, '_blank')
                }
            })
        } else {
            setAnchorEl(event.currentTarget)
            setAnchorElJob(job)
        }
    }

    const handleClosed = () => {
        setAnchorEl(null)
    }

    return (
        <SimpleCard
            title={'Job Reports'}
            isTotalDocsEnable={true}
            handleSearch={debouncedResults}
            total={totalDocs}
            label={'Job No. '}
            suggestions={materialNames}
            handleFilter={handleFilter}
            handleReset={handleReset}
            filterLabel={'Test'}
        >
            {loading ? (
                <MatxLoading />
            ) : (
                <Box width="100%">
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            height: '100%',

                            overflowX: 'auto',
                        }}
                    >
                        {' '}
                        <StyledTable>
                            <TableHead>
                                <TableRow hover>
                                    <TableCell align="center" key={'job_no'}>
                                        <TableSortLabel
                                            active={sorting.job_no}
                                            direction={
                                                sorting.job_no
                                                    ? sorting.job_no
                                                    : 'asc'
                                            }
                                            onClick={createSortHanlder(
                                                'job_no'
                                            )}
                                        >
                                            Job No
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="center">Test</TableCell>
                                    <TableCell align="center">
                                        Allocation
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        key={'start_date'}
                                    >
                                        <TableSortLabel
                                            active={sorting.start_date}
                                            direction={
                                                sorting.start_date
                                                    ? sorting.start_date
                                                    : 'asc'
                                            }
                                            onClick={createSortHanlder(
                                                'start_date'
                                            )}
                                        >
                                            Start Date
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell align="center" key={'due_date'}>
                                        <TableSortLabel
                                            active={sorting.due_date}
                                            direction={
                                                sorting.due_date
                                                    ? sorting.due_date
                                                    : 'asc'
                                            }
                                            onClick={createSortHanlder(
                                                'due_date'
                                            )}
                                        >
                                            Due Date
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell align="center">Status</TableCell>
                                    {configLicense.clientPublishing ===
                                        true && (
                                        <TableCell align="center">
                                            Publish Status
                                        </TableCell>
                                    )}

                                    <TableCell align="center">
                                        Actions
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {view &&
                                view.map((value, index) => {
                                    let tableDueDate = new Date(
                                        value?.due_date
                                    ).toLocaleDateString()
                                    let tableStartDate = new Date(
                                        value?.start_date
                                    ).toLocaleDateString()
                                    return (
                                        <TableBody key={index}>
                                            <TableRow hover>
                                                <TableCell align="center">
                                                    {value.job_no}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {value.material?.name}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {value.allocated_to?.name}
                                                </TableCell>
                                                {value.status !== 'TODO' ? (
                                                    <TableCell align="center">
                                                        {tableStartDate !==
                                                        'Invalid Date'
                                                            ? tableStartDate
                                                            : 'Not Yet Started'}
                                                    </TableCell>
                                                ) : (
                                                    <TableCell align="center">
                                                        Not Yet Started
                                                    </TableCell>
                                                )}
                                                <TableCell align="center">
                                                    {tableDueDate}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {statusBadge(value.status)}
                                                </TableCell>
                                                {configLicense.clientPublishing ===
                                                    true && (
                                                    <TableCell align="center">
                                                        {publishBadge(
                                                            value?.publish
                                                        )}
                                                    </TableCell>
                                                )}
                                                <TableCell align="center">
                                                    <Tooltip title="View" arrow>
                                                        <IconButton
                                                            onClick={(e) => {
                                                                OnhandleView(
                                                                    value
                                                                )
                                                            }}
                                                            data-testid="reportView"
                                                            id={index}
                                                        >
                                                            <StyledIcon>
                                                                visibility
                                                            </StyledIcon>
                                                        </IconButton>
                                                    </Tooltip>
                                                    {role !== 'CUSTOMER' &&
                                                    (value.status !==
                                                        'APPROVED' ||
                                                        value.status !==
                                                            'PAID') ? (
                                                        <Tooltip
                                                            title="Edit"
                                                            arrow
                                                        >
                                                            <IconButton
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    OnhandleEdit(
                                                                        value
                                                                    )
                                                                }}
                                                                data-testid="reportEdit"
                                                                id={index}
                                                            >
                                                                <StyledIcon>
                                                                    edit
                                                                </StyledIcon>
                                                            </IconButton>
                                                        </Tooltip>
                                                    ) : (
                                                        role !== 'CUSTOMER' && (
                                                            <IconButton
                                                                disabled
                                                            >
                                                                <Icon></Icon>
                                                            </IconButton>
                                                        )
                                                    )}
                                                    {/* {role !== 'CUSTOMER' && <Tooltip title='Delete' arrow>

                                                        < IconButton onClick={(e) => { handleOpen(value) }} disabled={!(value.status === 'TODO' || value.status === 'PROGRESS')}>
                                                            <StyledIcon
                                                            >
                                                                delete
                                                            </StyledIcon>
                                                        </IconButton>
                                                    </Tooltip>} */}
                                                    {/* <Tooltip title='Report' arrow>
                                                        <div>
                                                            <IconButton onClick={handleOpened} disabled={value.status === 'TODO' || value.status === 'Progress'} color='primary'
                                                                        data-testid="reportpdf"
                                                                        id={index}
                                                            >
                                                                <Icon>
                                                                    picture_as_pdf
                                                                </Icon>
                                                            </IconButton>
                                                            <CustomBackdrop
                                                                open={opened}
                                                                onClose={handleClosed}
                                                                aria-labelledby="parent-modal-title"
                                                                aria-describedby="parent-modal-description"
                                                            >
                                                                <Box sx = {{ ...style , '& button': { m: 1} }}>
                                                                    <IconButton variant="contained"
                                                                                style={{
                                                                                    height: '10px',
                                                                                    top: '6px',
                                                                                    left: '105px',
                                                                                }}
                                                                                onClick={handleClosed}>
                                                                        <Icon>
                                                                            close
                                                                        </Icon>
                                                                    </IconButton>
                                                                    <StyledButton variant="contained" onClick={()=>{handleReport(value, value.material?.name)}}>Report</StyledButton>
                                                                    <StyledButton variant="contained" onClick={()=>{handleReportOnly(value, value.material?.name)}}>Report Only</StyledButton>
                                                                </Box>
                                                            </CustomBackdrop>
                                                        </div>
                                                   </Tooltip> */}
                                                    <Tooltip
                                                        title="Report"
                                                        arrow
                                                    >
                                                        <div>
                                                            <IconButton
                                                                onClick={(e) =>
                                                                    handleClick(
                                                                        e,
                                                                        value
                                                                    )
                                                                }
                                                                disabled={
                                                                    value.status ===
                                                                        'TODO' ||
                                                                    value.status ===
                                                                        'Progress'
                                                                }
                                                                color="primary"
                                                                data-testid="reportpdf"
                                                                id={index}
                                                            >
                                                                <Icon>
                                                                    picture_as_pdf
                                                                </Icon>
                                                            </IconButton>
                                                        </div>
                                                    </Tooltip>
                                                    <Menu
                                                        id="basic-menu"
                                                        anchorEl={anchorEl}
                                                        open={open1}
                                                        onClose={handleClosed}
                                                        PaperProps={{
                                                            elevation: 0,
                                                            sx: {
                                                                overflow:
                                                                    'visible',
                                                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
                                                                mt: 1.5,
                                                                '& .MuiAvatar-root':
                                                                    {
                                                                        width: 32,
                                                                        height: 32,
                                                                        ml: -0.5,
                                                                        mr: 1,
                                                                    },
                                                                '&:before': {
                                                                    content:
                                                                        '""',
                                                                    display:
                                                                        'block',
                                                                    position:
                                                                        'absolute',
                                                                    top: 0,
                                                                    right: 14,
                                                                    width: 10,
                                                                    height: 10,
                                                                    bgcolor:
                                                                        'background.paper',
                                                                    transform:
                                                                        'translateY(-50%) rotate(45deg)',
                                                                    zIndex: 0,
                                                                },
                                                            },
                                                        }}
                                                        transformOrigin={{
                                                            horizontal: 'right',
                                                            vertical: 'top',
                                                        }}
                                                        anchorOrigin={{
                                                            horizontal: 'right',
                                                            vertical: 'bottom',
                                                        }}
                                                        key={`report_menu_${value._id}`}
                                                    >
                                                        <MenuItem
                                                            sx={{
                                                                color: 'primary.main',
                                                            }}
                                                            key={`report_${value._id}`}
                                                            onClick={
                                                                handleReport
                                                            }
                                                        >
                                                            Report
                                                        </MenuItem>
                                                        <MenuItem
                                                            sx={{
                                                                color: 'primary.main',
                                                            }}
                                                            key={`report_only_${value._id}`}
                                                            onClick={
                                                                handleReportOnly
                                                            }
                                                        >
                                                            ReportOnly
                                                        </MenuItem>
                                                    </Menu>

                                                    <Tooltip
                                                        title="Test Report"
                                                        arrow
                                                    >
                                                        <IconButton
                                                            onClick={(e) => {
                                                                handleTestReport(
                                                                    value,
                                                                    value
                                                                        .material
                                                                        ?.name
                                                                )
                                                            }}
                                                            color="primary"
                                                            disabled={
                                                                getDocReport.includes(
                                                                    value
                                                                        .material
                                                                        ?.name
                                                                ) === true ||
                                                                value.status ===
                                                                    'TODO' ||
                                                                value.status ===
                                                                    'Progress'
                                                            }
                                                            data-testid="reportobserationpdf"
                                                            id={index}
                                                        >
                                                            <Icon>article</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip
                                                        title="Observation"
                                                        arrow
                                                    >
                                                        <IconButton
                                                            onClick={(e) => {
                                                                handleObservation(
                                                                    value
                                                                )
                                                            }}
                                                            color="primary"
                                                            disabled={
                                                                getDocReport.includes(
                                                                    value
                                                                        .material
                                                                        ?.name
                                                                ) === true ||
                                                                value.status ===
                                                                    'TODO'
                                                            }
                                                            data-testid="reportobservation"
                                                            id={index}
                                                        >
                                                            <Icon>
                                                                description
                                                            </Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                    {/* <Tooltip title='Report Only' arrow>


                                                        <IconButton onClick={(e) => { handleReportOnly(value, value.material?.name) }} disabled={value.status === 'TODO' || value.status === 'Progress'} color='primary'
                                                         data-testid="reportonlypdf"
                                                         id={index}
                                                        >
                                                            <Icon>
                                                            subject
                                                            </Icon>
                                                        </IconButton>
                                                    </Tooltip> */}
                                                    {(role === 'ADMIN' || role === 'SUPER_ADMIN' || role === 'DEMO') &&
                                                        configLicense.clientPublishing ===
                                                            true &&
                                                        (value.status ===
                                                            'APPROVED' ||
                                                            value.status ===
                                                                'PAID' ||
                                                            value.status ===
                                                                'COMPLETE') && (
                                                            <Tooltip
                                                                title="Publish"
                                                                arrow
                                                            >
                                                                <IconButton
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        handlePublishOpen(
                                                                            value
                                                                        )
                                                                    }}
                                                                    disabled={
                                                                        value.status ===
                                                                            'TODO' ||
                                                                        value.status ===
                                                                            'PROGRESS' ||
                                                                        value.publish ===
                                                                            true
                                                                    }
                                                                    color="primary"
                                                                    data-testid="reportPublish"
                                                                    id={index}
                                                                >
                                                                    <Icon>
                                                                        publish
                                                                    </Icon>
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                        // </Tooltip>
                                    )
                                })}
                        </StyledTable>
                        {/* Delete Dialogue */}
                        <Dialog
                            // fullScreen={fullScreen}
                            value={value}
                            open={open}
                            onClose={handleClose}
                            TransitionComponent={Transition}
                        >
                            <DialogContent>
                                <DialogContentText>
                                    Are you sure you want to delete?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button
                                    onClick={(e) => handleDelete(value)}
                                    autoFocus
                                    variant="contained"
                                >
                                    Delete
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {/* Publish Dialog */}
                        <Dialog
                            // fullScreen={fullScreen
                            value={value}
                            open={dialog}
                            onClose={handlePublishClose}
                            TransitionComponent={Transition}
                        >
                            <DialogContent>
                                <DialogContentText>
                                    Are you sure you want to Publish this Job?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus onClick={handlePublishClose}>
                                    Cancel
                                </Button>
                                <Button
                                    onClick={(e) => handlePublish(value)}
                                    autoFocus
                                    variant="contained"
                                    endIcon={<Icon>publish</Icon>}
                                >
                                    Publish
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Snackbar
                            open={snack.snackOpen}
                            severity={snack.snackVaraint}
                            autoHideDuration={2000}
                            onClose={() =>
                                setSnack({
                                    ...snack,
                                    snackOpen: false,
                                })
                            }
                        >
                            <Alert
                                onClose={handleSnackClose}
                                severity="success"
                            >
                                {snack.snackMsg}
                            </Alert>
                        </Snackbar>
                        {/* {boolean === true && ( */}
                        {/* {doc && <DocViewer documents={[{ uri: doc, name: 'Document.docx' }]} />} */}
                        {/* {doc && <img src={`data:image/png;base64,${doc}`} />} */}
                        {/* )}  */}
                        <Grid item>
                            <Pagination
                                onChange={(e, val) => handleChangePage(val)}
                                page={pages}
                                count={resData}
                                variant="text"
                                // shape="rounded"
                                color="primary"
                            />
                        </Grid>
                    </Grid>
                </Box>
            )}
        </SimpleCard>
    )
}

export default ReportTable
