import {
    deleteSampleDataByArchived,
    deleteSampleDataByID,
    fetchCustomerList,
    fetchDisciplineList,
    fetchGroupList,
    fetchSampleList,
    fetchTestSampleList,
    fetchProgressData,
    fetchUlrNo,
    fetchUserList,
    fetchViewData,
    getSampleCollection,
    publishCustomerSample,
    sortTestSampleData,
    fetchProjects,
} from 'app/services/TestService'
import { TEST } from './Actions'

export const getTestTableData = (props, callBack) => {
    fetchTestSampleList(
        props,
        (res) => callBack(res?.data?.data),
        (err) => console.log(err)
    )
}
//TestTable statusCount
export const getTestProgressData = (props, callBack) => {
    fetchProgressData(
        props,
        (res) => callBack(res?.data),
        (err) => console.log(err)
    )
}

// Test Table Sorting
export const getSortedTestTableList = (props, callBack) => {
    sortTestSampleData(
        props,
        (res) => callBack(res?.data?.data),
        (err) => console.log(err)
    )
}

export const deleteSampleByArchived = (props, callBack) => {
    deleteSampleDataByArchived(
        props,
        (res) => callBack(res),
        (err) => console.log(err)
    )
}

export const deleteSampleById = (test_id, callBack) => {
    deleteSampleDataByID(
        test_id,
        (res) => callBack(res?.data),
        (err) => console.log(err)
    )
}

export const getTestView = (test_id, callBack) => {
    fetchViewData(
        test_id,
        (res) => callBack(res?.data),
        (err) => console.log(err)
    )
}

export const getSampleCollectionView = (test_id, callBack) => {
    getSampleCollection(
        test_id,
        (res) => callBack(res?.data),
        (err) => console.log(err)
    )
}

export const getUserData = (props, callBack) => {
    fetchUserList(
        props,
        (res) => callBack(res?.data?.data?.docs),

        (err) => console.log(err)
    )
}

export const getAssigneeList = (role) => (dispatch) => {
    fetchUserList(
        role,
        (res) =>
            dispatch({
                type: TEST.SET_ASSIGNEE_DATA,
                payload: res?.data?.data?.docs,
            }),
        (err) => console.log(err)
    )
}

export const getReviewerData = (role) => (dispatch) => {
    fetchUserList(
        role,
        (res) =>
            dispatch({
                type: TEST.SET_REVIEWER_DATA,
                payload: res?.data?.data?.docs,
            }),
        (err) => console.log(err)
    )
}
export const getProjects = () => (dispatch) => {
    fetchProjects(
        (res) =>
            dispatch({
                type: TEST.SET_PROJECTS,
                payload: res?.data,
            }),
        (err) => console.log(err)
    )
}
//Discipline
export const getDiscipline = () => (dispatch) => {
    fetchDisciplineList(
        (res) =>
            dispatch({
                type: TEST.SET_DISCIPLINE_DATA,
                payload: res?.data,
            }),
        (err) => console.log(err)
    )
}
export const getGroupListById = (discipline_id) => (dispatch) => {
    fetchGroupList(
        discipline_id,
        (res) =>
            dispatch({
                type: TEST.SET_GROUP_DATA,
                payload: res?.data,
            }),
        (err) => console.log(err)
    )
}

export const getSampleListById = (group_id) => (dispatch) => {
    fetchSampleList(
        group_id,
        (res) =>
            dispatch({
                type: TEST.SET_SAMPLE_DATA,
                payload: res?.data,
            }),
        (err) => console.log(err)
    )
}

// export const getUlrNo = () => (dispatch) => {
//     fetchUlrNo(
//         (res) =>
//             dispatch({
//                 type: TEST.SET_ULR_NO,
//                 payload: res?.data?.ulr,
//             }),
//         (err) => console.log(err)
//     )
// }

export const getUlrNo = (previousUlrs, callBack) => {
    fetchUlrNo(
        previousUlrs,
        (res) => callBack(res?.data),
        (err) => console.log(err)
    )
}

//CustomerSearch
export const getCustomerSearch = (props) => (dispatch) => {
    fetchCustomerList(
        props,
        (res) => {
            dispatch({
                type: TEST.SET_CUSTOMER_DATA,
                payload: res?.data?.data,
            })
        },

        (err) => console.log(err)
    )
}
//CLIENT PUBLISH
export const publishCustomerSampleByID = (id, callBack) => {
    publishCustomerSample(
        id,
        (res) => callBack(res),

        (err) => console.log(err)
    )
}
