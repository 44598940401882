import {
    Card,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { AppContext } from 'app/contexts/AppContext'
import { StyledDivider } from 'app/views/jobs/StyledComponents/StyledComponents'
import Cookies from 'js-cookie'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { HelperText } from 'app/views/jobs/StyledComponents/StyledComponents'

const validationSchema = Yup.object().shape({
    date: Yup.string().required('Required'),
    startedOn: Yup.string().required('Required'),
    completedOn: Yup.string().required('Required'),
})
const JobDetails = (props) => {
    const {
        isView,
        jobDetail,
        reason,
        standard_ref,
        onhandleReason,
        onhandleStandard_ref,
        initialState,
        onhandleChange,
    } = props
    const { setStatusData } = useContext(AppContext)
    const [Status, setStatus] = useState(jobDetail?.status)
    useEffect(() => {
        setStatus(jobDetail?.status)
    }, [jobDetail])

    // const standard_ref = {
    //     standard_ref: jobDetail.standard_ref.filter((c, index) => {
    //         return jobDetail.standard_ref.indexOf(c) === index;
    //     })
    // };

    const status = {
        SUPER_ADMIN: [
            { label: 'Todo', value: 'TODO' },
            { label: 'Progress', value: 'PROGRESS' },
            { label: 'Complete', value: 'COMPLETE' },
            { label: 'Approved', value: 'APPROVED' },
            { label: 'Paid', value: 'PAID' },
        ],
        ADMIN: [
            { label: 'Todo', value: 'TODO' },
            { label: 'Progress', value: 'PROGRESS' },
            { label: 'Complete', value: 'COMPLETE' },
            { label: 'Approved', value: 'APPROVED' },
            { label: 'Paid', value: 'PAID' },
        ],
        DEMO: [
            { label: 'Todo', value: 'TODO' },
            { label: 'Progress', value: 'PROGRESS' },
            { label: 'Complete', value: 'COMPLETE' },
            { label: 'Approved', value: 'APPROVED' },
            { label: 'Paid', value: 'PAID' },
        ],
        LAB_TECHNICIANS: [
            { label: 'Todo', value: 'TODO' },
            { label: 'Progress', value: 'PROGRESS' },
            { label: 'Complete', value: 'COMPLETE' },
        ],
        TECHNICAL_MANAGER: [
            { label: 'Todo', value: 'TODO' },
            { label: 'Progress', value: 'PROGRESS' },
            { label: 'Complete', value: 'COMPLETE' },
            { label: 'Approved', value: 'APPROVED' },
        ],
        CUSTOMER: [
            { label: 'Todo', value: 'TODO' },
            { label: 'Progress', value: 'PROGRESS' },
            { label: 'Complete', value: 'COMPLETE' },
            { label: 'Approved', value: 'APPROVED' },
        ],
    }
    let roles = Cookies.get('userRole')

    const handleStatusChange = (e) => {
        setStatus(e.target.value)
    }
    setStatusData(Status)
    return (
        // <Grid item xs={12}    >
        <>
            <Formik
                initialValues={initialState}
                validationSchema={validationSchema}
            >
                {(props) => {
                    const {
                        // values,
                        errors,
                        touched,

                        handleBlur,
                        handleSubmit,
                    } = props
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Card>
                                <CardHeader title="Job Details" />
                                <StyledDivider />
                                <CardContent>
                                    <Grid
                                        container
                                        spacing={2}
                                        justifyContent="center"
                                    >
                                        <Grid item xs={12} md={5}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <InputLabel>
                                                        Job No
                                                    </InputLabel>
                                                    <TextField
                                                        disabled
                                                        multiline
                                                        variant="standard"
                                                        value={
                                                            jobDetail?.job_no
                                                        }
                                                        size="small"
                                                        sx={{ minWidth: 250 }}
                                                    />
                                                </Grid>
                                                {!isView ? (
                                                    <Grid item xs={12}>
                                                        <FormControl
                                                            variant="standard"
                                                            sx={{
                                                                minWidth: 250,
                                                            }}
                                                        >
                                                            <InputLabel>
                                                                Status
                                                            </InputLabel>
                                                            <Select
                                                                value={Status}
                                                                onChange={
                                                                    handleStatusChange
                                                                }
                                                                disabled={
                                                                    isView
                                                                }
                                                            >
                                                                <MenuItem
                                                                    value=""
                                                                    disabled
                                                                >
                                                                    Select
                                                                </MenuItem>
                                                                {(roles
                                                                    ? status[
                                                                          roles
                                                                      ]
                                                                    : []
                                                                ).map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => (
                                                                        <MenuItem
                                                                            key={
                                                                                index
                                                                            }
                                                                            value={
                                                                                item.value
                                                                            }
                                                                        >
                                                                            {
                                                                                item.label
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                ) : (
                                                    <Grid item xs={12}>
                                                        <InputLabel>
                                                            Status
                                                        </InputLabel>
                                                        <TextField
                                                            disabled
                                                            variant="standard"
                                                            value={
                                                                jobDetail?.status
                                                            }
                                                            size="small"
                                                            sx={{
                                                                minWidth: 250,
                                                            }}
                                                        />
                                                    </Grid>
                                                )}
                                                <Grid item xs={12}>
                                                    <LocalizationProvider
                                                        dateAdapter={
                                                            AdapterDateFns
                                                        }
                                                    >
                                                        <DatePicker
                                                            label="Test Started On"
                                                            disabled={isView}
                                                            inputFormat="dd/MM/yyyy"
                                                            value={
                                                                initialState?.startedOn
                                                            }
                                                            name="startedOn"
                                                            onBlur={handleBlur}
                                                            onChange={(date) =>
                                                                onhandleChange({
                                                                    target: {
                                                                        value: date,
                                                                        name: 'startedOn',
                                                                    },
                                                                })
                                                            }
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    sx={{
                                                                        minWidth: 250,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                    <HelperText>
                                                        {errors.startedOn &&
                                                        touched.startedOn
                                                            ? errors.startedOn
                                                            : ''}
                                                    </HelperText>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} md={5}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} mt={-0.5}>
                                                    <InputLabel>
                                                        Type of Test
                                                    </InputLabel>
                                                    <TextField
                                                        disabled
                                                        variant="standard"
                                                        value={
                                                            jobDetail?.material
                                                                ?.name
                                                        }
                                                        size="small"
                                                        sx={{ minWidth: 250 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <LocalizationProvider
                                                        dateAdapter={
                                                            AdapterDateFns
                                                        }
                                                    >
                                                        <DatePicker
                                                            disabled
                                                            label="Due Date"
                                                            inputFormat="dd/MM/yyyy"
                                                            value={
                                                                jobDetail?.due_date
                                                            }
                                                            name="date"
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    sx={{
                                                                        minWidth: 250,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <LocalizationProvider
                                                        dateAdapter={
                                                            AdapterDateFns
                                                        }
                                                    >
                                                        <DatePicker
                                                            label="Test Completed On"
                                                            disabled={isView}
                                                            inputFormat="dd/MM/yyyy"
                                                            value={
                                                                initialState?.completedOn
                                                            }
                                                            name="completedOn"
                                                            onChange={(date) =>
                                                                onhandleChange({
                                                                    target: {
                                                                        value: date,
                                                                        name: 'completedOn',
                                                                    },
                                                                })
                                                            }
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    sx={{
                                                                        minWidth: 250,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                    <HelperText>
                                                        {errors.completedOn &&
                                                        touched.completedOn
                                                            ? errors.completedOn
                                                            : ''}
                                                    </HelperText>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <InputLabel>Remark</InputLabel>
                                            <TextField
                                                disabled={isView}
                                                multiline
                                                fullWidth
                                                variant="standard"
                                                value={reason}
                                                size="small"
                                                onChange={onhandleReason}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <InputLabel>
                                                Standard Ref
                                            </InputLabel>
                                            <TextField
                                                multiline
                                                fullWidth
                                                variant="standard"
                                                value={standard_ref}
                                                size="small"
                                                onChange={onhandleStandard_ref}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                            {/* <JobEdit Status = {Status} /> */}
                        </Form>
                    )
                }}
            </Formik>
        </>
    )
}

export default JobDetails
