import axios from 'axios'
import Cookies from 'js-cookie'
import { API_BASE_URL } from '../../api/Action'

const Axios = axios.create({
    baseURL: `${API_BASE_URL}/api`,
})
const errorHandler = (error) => {
    return Promise.reject(error)
}

Axios.interceptors.request.use(
    function (request) {
        const accessToken = Cookies.get('token')
        if (accessToken) {
            request.headers = {
                ...request.headers,
                Authorization: `Bearer ${accessToken}`,
            }
        }
        const NABL = Cookies.get('request_type')
        if (NABL === 'NABL') {
            request.headers = {
                ...request.headers,
                'request-type': 'NABL',
            }
        }
        const organizationId = Cookies.get('organizationId')
        console.log(organizationId,'orrrr');
        
        if (organizationId) {
            request.headers = {
                ...request.headers,
                'organization-id': organizationId,
            }
        }
        return request
    },
    (error) => errorHandler(error)
)

Axios.interceptors.response.use(
    function (response) {
        return response
    },
    function (error) {
        let status = error?.response?.status
        let msg = error?.response?.data?.message
        if (status === 403 || msg === 'Unauthorized') {
            return window.location.replace('/')
        }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error)
    }
)

export default Axios
