import { MatxLoading } from 'app/components'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { createContext, useContext, useEffect, useReducer } from 'react'
import { AppContext } from './AppContext'
import Axios from 'app/configs/Axios'

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false
    }

    const decodedToken = jwtDecode(accessToken)
    const currentTime = Date.now() / 1500
    return decodedToken.exp > currentTime
}

const setSession = (
    token,
    name,
    _id,
    userName,
    organizationsData,
    request_type,
    create_userId
) => {
    if (token) {
        Cookies.set('token', token)
        Cookies.set('userRole', name)
        Cookies.set('userId', _id)
        Cookies.set('organizations', organizationsData)
        Cookies.set('userName', userName)
        Cookies.set('request_type', request_type)
        Cookies.set('create_userId', create_userId)
    } else {
        Cookies.remove('token')
        Cookies.remove('userRole')
        Cookies.remove('userId')
        Cookies.remove('organizations')
        Cookies.remove('userName')
        Cookies.remove('request_type')
        Cookies.remove('organizationId')
        Cookies.remove('create_userId', create_userId)
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                userRole: sessionStorage.removeItem('userRole'),
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => {},
    register: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const { setRole } = useContext(AppContext)

    const login = async (data) => {
        const response = await Axios.put(`/login`, data)
        console.log(response.data.data.currentUser.loginUserId,'response');
        
        const { token } = response.data.data
        const { organizations } = response.data.data.currentUser
        let [...organizationsData] = organizations
        const { name } = response.data.data.currentUser.roles
        const userName = response?.data?.data?.currentUser?.name
        const { _id } = response.data.data.currentUser
        const pathname = window.location.pathname
        const request_type = pathname === '/session/falcon-demo' && 'NABL' 
        console.log(request_type,'uuuu');
        const create_userId = response.data.data.currentUser.loginUserId
        
        setRole(response.data.data.currentUser.roles.name)
        setSession(
            token,
            name,
            _id,
            userName,
            JSON.stringify(organizationsData),
            request_type,
            create_userId
        )
        dispatch({
            type: 'LOGIN',
            payload: {
                user: name,
            },
        })
    }

    const register = async ( name,email, password,phoneNumber,address,roles,branch,numberOfDays,) => {
        const response = await Axios.post('/register', {
            name,
            email,
           // userName,
            password,
            phoneNumber,address,roles,branch,numberOfDays,
        })

        const { accessToken, user } = response.data

        setSession(accessToken)

        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        })
    }

    const logout = () => {
        setSession(null)
        dispatch({ type: 'LOGOUT' })
        window.location.reload()
    }

    useEffect(() => {
        ;(async () => {
            try {
                const accessToken = Cookies.get('token')
                if (accessToken && isValidToken(accessToken)) {
                    const user = Cookies.get('userRole')

                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user,
                        },
                    })
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                register,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
