import { Alert, Button, Grid, Snackbar } from '@mui/material'
import { ContentBox } from 'app/assets/styled-components/SharedComponents'
import { Breadcrumb, MatxLoading } from 'app/components'
import DeleteDilaog from 'app/components/Dilaog/DeleteDilaog'
import {
    deleteArchivedJobById,
    deleteJobById,
    getJobView,
} from 'app/redux/actions/JobActions'
import {
    getNotificationJobData,
    getNotificationsData,
} from 'app/redux/actions/NotificationAction'
import Cookies from 'js-cookie'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import InvoiceDetails from './InvoiceDetails'
import JobCardTable from './jobCards'
import JobDetails from './JobDetails'
import { getDocReport } from 'app/assets/Constant/constant'
import JobCardTableDoc from './JobCardTableDoc'

function JobView() {
    const initialValues = {
        invoice_no: '',
        status: '',
        date: '',
        paymentPaid: '',
        paymentGP: '',
    }
    const params = useParams()
    const navigate = useNavigate()
    const userRole = Cookies.get('userRole')
    let user_id = Cookies.get('userId')

    const [EditDetails, setEditDetails] = useState({})
    const [invoiceData, setInvoiceData] = useState(initialValues)
    const [reason, setReason] = useState('')
    const [loading, setLoading] = useState(true)
    const [openDialog, setOpenDialog] = useState(false)

    const [snack, setSnack] = useState({
        snackOpen: false,
        snackVaraint: '',
        snackMsg: '',
    })
    const dispatch = useDispatch()
    const materialName = EditDetails?.data?.material?.name

    const fetchNotification = () => {
        dispatch(
            getNotificationsData({
                user_id: user_id,
                status: 'ARCHIVED',
                visited: false,
                size: 100,
            })
        )
        dispatch(
            getNotificationJobData({
                user_id: user_id,
                status: 'ARCHIVED',
                visited: false,
                size: 100,
            })
        )
    }

    const handleDelete = () => {
        let jobId = EditDetails?.data?._id
        deleteJobById(jobId, (res) => {
            console.log(res)
            // let msg = res?.Message
            // if (res.status === 200) {
            setSnack({
                ...snack,
                snackOpen: 'true',
                snackMsg: 'Job Delete Successfully',
                snackVaraint: 'success',
            })
            setTimeout(() => {
                navigate(-1)
            }, 500)

            // } else {
            //     setSnack({
            //         ...snack,
            //         snackOpen: 'false',
            //         snackMsg: msg,
            //         snackVaraint: 'error'
            //     })
            // }
        })
    }

    const handleRestore = () => {
        let status = { status: 'TODO' }
        let jobId = EditDetails?.data?._id
        deleteArchivedJobById({ jobId, status }, (res) => {
            let msg = res?.Message

            if (res.status === 200) {
                fetchNotification()
                setSnack({
                    ...snack,
                    snackOpen: 'true',
                    snackMsg: 'Restore Successfully',
                    snackVaraint: 'success',
                })
                setTimeout(() => {
                    navigate(-1)
                }, 500)
            } else {
                setSnack({
                    ...snack,
                    snackOpen: 'false',
                    snackMsg: msg,
                    snackVaraint: 'error',
                })
            }
        })
    }

    useEffect(() => {
        getJobView(params.id, (res) => {
            setEditDetails(res)
            setInvoiceData(res?.data?.invoice_id)
            setReason(res?.data?.reason)
            setLoading(false)
        })
    }, [])

    const handleOpenDialog = () => {
        setOpenDialog(true)
    }
    const handleDialogClose = () => {
        setOpenDialog(false)
    }

    const OnhandleEdit = () => {
        navigate(`/job/edit/${params.id}`)
    }

    const handleSnackClose = () => setSnack(false)

    if (loading) {
        return <MatxLoading />
    } else {
        return (
            <Fragment>
                <ContentBox>
                    <Grid container spacing={2}>
                        <Breadcrumb
                            routeSegments={[
                                { name: 'Jobs ', path: '/job' },
                                { name: 'Job View' },
                            ]}
                        />

                        <Grid item xs={12}>
                            <JobDetails
                                isView={true}
                                jobDetail={EditDetails?.data}
                                reason={reason}
                            />
                        </Grid>
                        {EditDetails &&
                            EditDetails.data &&
                            EditDetails.data.result &&
                            EditDetails.data.result.map((value) => (
                                <JobCardTable
                                    key={value.groupName}
                                    result={value}
                                    materialName={
                                        EditDetails?.data?.material?.name
                                    }
                                    isView={true}
                                />
                            ))}

                        {getDocReport.includes(materialName) && (
                            <JobCardTableDoc
                                result={EditDetails?.data?.material?.name}
                                materialId={EditDetails?.data?.material?._id}
                                setSnack={setSnack}
                                snack={snack}
                                isView={true}
                                document={EditDetails?.data?.document}
                            />
                        )}

                        {(userRole === 'ADMIN' || userRole === 'SUPER_ADMIN' || userRole === 'DEMO') && (
                            <Grid item xs={12}>
                                <InvoiceDetails
                                    isView={true}
                                    initialState={invoiceData}
                                />
                            </Grid>
                        )}

                        <Grid
                            container
                            spacing={1}
                            sx={{ mt: 1 }}
                            justifyContent="end"
                        >
                            <Grid item>
                                <Button
                                    type="submit"
                                    size="small"
                                    color={'inherit'}
                                    variant="contained"
                                    onClick={() => {
                                        navigate('/job')
                                    }}
                                >
                                    Back
                                </Button>
                            </Grid>
                            {userRole === 'LAB_TECHNICIANS' ||
                                ((userRole === 'ADMIN' || userRole === 'SUPER_ADMIN' || userRole === 'DEMO') &&
                                    EditDetails?.data?.status !==
                                        'ARCHIVED' && (
                                        <Grid item>
                                            <Button
                                                type="submit"
                                                size="small"
                                                variant="contained"
                                                onClick={OnhandleEdit}
                                            >
                                                Edit
                                            </Button>
                                        </Grid>
                                    ))}
                            {userRole !== 'CUSTOMER' &&
                                EditDetails?.data?.status === 'ARCHIVED' && (
                                    <Grid item>
                                        <Button
                                            type="submit"
                                            disabled={
                                                (userRole === 'ADMIN' || userRole === 'SUPER_ADMIN' || userRole === 'DEMO')||
                                                userRole === 'TECHNICAL_MANAGER'
                                                    ? false
                                                    : true
                                            }
                                            size="small"
                                            variant="outlined"
                                            onClick={() => {
                                                handleOpenDialog()
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    </Grid>
                                )}
                            {userRole !== 'CUSTOMER' &&
                                EditDetails?.data?.status === 'ARCHIVED' && (
                                    <Grid item>
                                        <Button
                                            type="submit"
                                            disabled={
                                                (userRole === 'ADMIN' || userRole === 'SUPER_ADMIN' || userRole === 'DEMO') ||
                                                userRole === 'TECHNICAL_MANAGER'
                                                    ? false
                                                    : true
                                            }
                                            size="small"
                                            variant="contained"
                                            onClick={() => {
                                                handleRestore()
                                            }}
                                        >
                                            Restore
                                        </Button>
                                    </Grid>
                                )}
                        </Grid>
                    </Grid>
                </ContentBox>
                <Snackbar
                    open={snack.snackOpen}
                    severity={snack.snackVaraint}
                    autoHideDuration={2000}
                    onClose={() =>
                        setSnack({
                            ...snack,
                            snackOpen: false,
                        })
                    }
                >
                    <Alert onClose={handleSnackClose} severity="success">
                        {snack.snackMsg}
                    </Alert>
                </Snackbar>

                <DeleteDilaog
                    // value={value}
                    open={openDialog}
                    handleClose={handleDialogClose}
                    handleDelete={handleDelete}
                />
            </Fragment>
        )
    }
}

export default JobView
