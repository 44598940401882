import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    Icon,
    IconButton,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    useTheme,
} from '@mui/material'
import { Box, styled } from '@mui/system'
import { MatxLoading, SimpleCard } from 'app/components'
import CustomizedSnackBars from 'app/components/SnackBar'
import {
    deleteArchivedSampleById,
    getSampleCollectionTableData,
} from 'app/redux/actions/SampleCollectionAction'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    Small,
    StyledIcon,
    Transition,
} from '../jobs/StyledComponents/StyledComponents'

const StyledTable = styled(Table)(({ theme }) => ({
    whiteSpace: 'pre',
    minWidth: 850,

    '& thead': {
        '& tr': {
            '& th': {
                paddingLeft: 0,
                paddingRight: 0,
                wordBreak: 'break-word',
                backgroundColor: '#f5f5f5',
                cursor: 'default',
            },
        },
    },
    '& tbody': {
        '& tr': {
            '& td': {
                paddingLeft: 0,
                textTransform: 'capitalize',
                wordBreak: 'break-word',
                cursor: 'default',
            },
        },
    },
}))

const SampleRequestTable = () => {
    const { palette } = useTheme()
    const bgPrimary = palette.primary.main
    const bgWarning = palette.warning.main
    const bgSuccess = palette.success.main

    const userRole = Cookies.get('userRole')
    const userId = Cookies.get('userId')
    const create_userId = Cookies.get('create_userId')

    const [open, setOpen] = useState(false)
    const [value, setValue] = useState({})
    const [sampleList, setSampleList] = useState([])
    const [totalPages, setTotalPages] = useState(1)
    const [snack, setSnack] = useState({
        snackOpen: false,
        snackVaraint: '',
        snackMsg: '',
    })

    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    useEffect(() => {
        getSampleCollectionTableData({ userId,create_userId }, (res) => {
            setSampleList(res?.docs)
            setTotalPages(res?.totalPages)
            setLoading(false)
        })
    }, [])

    const statusBadge = (status) => {
        if (status === 'TODO') return <Small bgcolor={bgPrimary}> TODO </Small>
        else if (status === 'COMPLETE')
            return <Small bgcolor={bgSuccess}> Completed </Small>
        else if (status === 'APPROVED')
            return <Small bgcolor={bgPrimary}> Approved </Small>
        else if (status === 'PAID')
            return <Small bgcolor={bgWarning}> Paid </Small>
        else return <Small bgcolor={bgWarning}> Progress</Small>
    }

    const handleChangePage = (val) => {
        getSampleCollectionTableData({ userId, page: val,create_userId }, (res) => {
            setSampleList(res?.docs)
            setTotalPages(res?.totalPages)
            setLoading(false)
        })
    }
    const handleOpen = (value) => {
        setOpen(true)
        setValue(value)
    }

    const handleClose = () => setOpen(false)

    const handleDelete = (value, index) => {
        let status = { status: 'ARCHIVED' }
        console.log(value._id)
        let jobId = value._id
        deleteArchivedSampleById({ jobId, status }, (res) => {
            let msg = res?.Message
            setOpen(false)
            let _data = sampleList.filter((v, i) => v._id !== value._id)
            setSampleList(_data)
            if (res?.status === 'ARCHIVED') {
                setSnack({
                    ...snack,
                    snackOpen: 'true',
                    snackMsg: 'Sample Delete Successfully',
                    snackVaraint: 'success',
                })
            } else {
                setSnack({
                    ...snack,
                    snackOpen: 'false',
                    snackMsg: msg,
                    snackVaraint: 'error',
                })
            }
        })
    }

    // const handleRequestSort = (event, property) => {
    //     const isAscending =
    //         valueOrderBy === property && orderDirection === 'asc'

    //     setOrderDirection(isAscending ? 'desc' : 'asc')

    //     let data
    //     axios
    //         .get(`${API_BASE_URL}/api/customerSample`, {
    //             params: {
    //                 [property]: sorting[property] === 'asc' ? 'desc' : 'asc',
    //                 page: pages,
    //                 size: 10,
    //             },
    //         })
    //         .then((res) => {
    //             data = res.data
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }

    if (loading) {
        return <MatxLoading />
    } else {
        return (
            <SimpleCard title="Samples">
                <Box width="100%">
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            height: '100%',
                            overflowX: 'auto',
                        }}
                    >
                        <StyledTable>
                            <TableHead>
                                <TableRow hover>
                                    <TableCell
                                        align="center"
                                        key={'sampleName'}
                                    >
                                        Customer Name
                                    </TableCell>
                                    <TableCell align="center">
                                        Sample Collected Date
                                    </TableCell>
                                    {(userRole === 'ADMIN' || userRole === 'SUPER_ADMIN' || userRole === 'DEMO') && (
                                        <TableCell align="center">
                                            Assignee
                                        </TableCell>
                                    )}
                                    <TableCell align="center">Status</TableCell>
                                    <TableCell align="center">
                                        Actions
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sampleList?.map((sample, index) => {
                                    let date = new Date(
                                        sample?.customer_sample
                                            ?.sample_created_at ??
                                            sample?.customer_sample?.createdAt
                                    ).toLocaleDateString()
                                    return (
                                        <>
                                            <TableRow key={index} hover>
                                                <TableCell align="center">
                                                    {sample?.customer
                                                        ? sample?.customer?.name
                                                        : sample
                                                              ?.customer_sample
                                                              ?.customerName}
                                                </TableCell>

                                                <TableCell align="center">
                                                    {date}
                                                </TableCell>
                                                {(userRole === 'ADMIN' || userRole === 'SUPER_ADMIN' || userRole === 'DEMO') && (
                                                    <TableCell align="center">
                                                        {
                                                            sample?.collector
                                                                ?.name
                                                        }
                                                    </TableCell>
                                                )}

                                                <TableCell align="center">
                                                    {statusBadge(
                                                        sample?.status
                                                    )}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <IconButton
                                                        disabled={
                                                            sample?.status ===
                                                            'COMPLETE'
                                                        }
                                                        onClick={() =>
                                                            navigate(
                                                                `edit/${sample?._id}`
                                                            )
                                                        }
                                                        color="primary"
                                                    >
                                                        <Icon>edit</Icon>
                                                    </IconButton>

                                                    <IconButton
                                                        onClick={() =>
                                                            navigate(
                                                                `view/${sample?.customer_sample?._id}`
                                                            )
                                                        }
                                                    >
                                                        <StyledIcon>
                                                            visibility
                                                        </StyledIcon>
                                                    </IconButton>

                                                    {userRole !== 'COLLECTOR' &&
                                                        userRole !==
                                                            'CUSTOMER' && (
                                                            <Tooltip
                                                                title="Delete"
                                                                arrow
                                                            >
                                                                <IconButton
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        handleOpen(
                                                                            sample
                                                                        )
                                                                    }}
                                                                >
                                                                    <StyledIcon>
                                                                        delete
                                                                    </StyledIcon>
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    )
                                })}
                            </TableBody>
                        </StyledTable>
                        <Dialog
                            // fullScreen={fullScreen
                            value={value}
                            open={open}
                            onClose={handleClose}
                            TransitionComponent={Transition}
                        >
                            <DialogContent>
                                <DialogContentText>
                                    Are you sure you want to delete?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button
                                    onClick={(e, index) =>
                                        handleDelete(value, index)
                                    }
                                    autoFocus
                                    variant="contained"
                                >
                                    Delete
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <CustomizedSnackBars
                            open={snack.snackOpen}
                            severity={snack.snackVariant}
                            message={snack.snackMsg}
                            onClose={() =>
                                setSnack({
                                    ...snack,
                                    snackOpen: false,
                                })
                            }
                        />
                        {/* <Snackbar open={snack.snackOpen} severity={snack.snackVaraint}

                            autoHideDuration={2000} onClose={() =>
                                setSnack({
                                    ...snack,
                                    snackOpen: false,
                                })
                            }>
                            <Alert onClose={handleSnackClose} severity="success" >
                                {snack.snackMsg}
                            </Alert>
                        </Snackbar> */}

                        <Grid item>
                            <Pagination
                                onChange={(e, val) => handleChangePage(val)}
                                count={totalPages}
                                variant="text"
                                // shape="rounded"
                                color="primary"
                            />
                        </Grid>
                    </Grid>
                </Box>
            </SimpleCard>
        )
    }
}

export default SampleRequestTable
